import React from 'react';
import sideImagePath from './assets/log-in-absence-management.svg';
import Logo from './assets/logo';

const FullScreenLayout = ({ children }) => {
	return (
		<div className="flex flex-col items-center lg:items-center lg:flex-row">
			<div className="w-[40%] min-w-[600px]">
				<div
					role="img"
					aria-label="Side Image"
					className="flex-none self-start w-[100%] h-screen min-h-[775px] bg-no-repeat bg-top bg-cover hidden lg:inline-flex"
					style={{ backgroundImage: `url(${sideImagePath})` }}
				/>
			</div>
			<Logo role="img" aria-label="BrightHR Logo" className="lg:hidden w-[180px] m-10" />
			<div className="flex flex-row justify-center grow">{children}</div>
		</div>
	);
};

export default FullScreenLayout;
