import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import Button from '@brighthr/component-button';
import Alert from '@brighthr/component-alert';
import Input from 'components/FormikUI/FormikInput';
import PasswordFields from 'components/PasswordFields';
import postInviteDetails from 'services/postInviteDetails';
import { event } from 'utils/GA';
import getErrorMessage from 'utils/getErrorMessage';
import schema from './schema';
import determineInitialJurisdiction from '../determineInitialJurisdiction';
import JurisdictionSelect from '../JurisdictionSelect';
import AcceptTermsCheckbox from '../AcceptTermsCheckbox';
import SuccessfulUpdate from '../SuccessfulUpdate';

const gaCategory = 'OwnerInvite';

const shouldDisableButton = ({ validation, acceptTermsAndConditions, jurisdiction }) => {
	return !(
		validation.validation0 &&
		validation.validation1 &&
		validation.validation2 &&
		validation.validation3 &&
		acceptTermsAndConditions &&
		jurisdiction
	);
};

const SignUp = ({ data }) => {
	const { accountId } = useParams();

	const [successfulUpdate, setSuccessfulUpdate] = useState(false);
	const [error, setError] = useState(null);

	const { email, languageCode, _embedded } = data;

	const onSubmit = (values) => {
		const payload = {
			id: accountId,
			email: values.email,
			password: values.newPassword,
			acceptTermsAndConditions: values.acceptTermsAndConditions,
			jurisdictionCode: values.jurisdiction,
		};

		return postInviteDetails(accountId, payload)
			.then(() => {
				event({
					category: gaCategory,
					action: 'Submit registration',
				});
				setSuccessfulUpdate(true);
			})
			.catch((e) => {
				const errorResponse = e?.response?.data;

				setError(getErrorMessage(errorResponse));
			});
	};

	return (
		<div className="w-full mx-4 lg:mx-0 lg:w-1/2">
			{successfulUpdate ? (
				<SuccessfulUpdate>Account created</SuccessfulUpdate>
			) : (
				<Formik
					initialValues={{
						email,
						newPassword: '',
						confirmPassword: '',
						acceptTermsAndConditions: false,
						jurisdiction: determineInitialJurisdiction(_embedded.jurisdiction),
						validation: {
							validation0: undefined,
							validation1: undefined,
							validation2: undefined,
						},
					}}
					validationSchema={schema}
					onSubmit={onSubmit}
				>
					{(formik, { values, isValid } = formik) => {
						return (
							<form onSubmit={formik.handleSubmit}>
								<JurisdictionSelect
									formik={formik}
									jurisdictions={_embedded.jurisdiction}
									languageCode={languageCode}
								/>
								<h1 className="mt-4 mb-2 text-lg semi-bold">Your details</h1>
								<Input
									val={values.email}
									label="Email"
									name="email"
									type="text"
									onChange={formik.handleChange}
								/>
								<PasswordFields formik={formik} />
								<div className="my-2">
									<AcceptTermsCheckbox
										values={values}
										formik={formik}
										gaCategory={gaCategory}
										tenant={languageCode}
									/>
									{error && (
										<Alert
											type="error"
											icon="error"
											title="Error"
											border
											dismiss
											fullWidth
											data-testid="error"
											className="mb-2"
										>
											{error}
										</Alert>
									)}
									<Button
										type="submit"
										isLoading={formik.isSubmitting}
										disabled={
											!isValid ||
											shouldDisableButton(values) ||
											formik.isSubmitting
										}
										text="Continue"
									/>
								</div>
							</form>
						);
					}}
				</Formik>
			)}
		</div>
	);
};

export default SignUp;
