import React from 'react';
import { removeAllLocalStorageItems } from 'hooks/useAuthentication';

/** This component is rendered via an iframe by the auth app on auth logout to ensure that locally stored tokens are destroyed for all apps */
const SilentLogout = () => {
	removeAllLocalStorageItems();

	// This won't be displayed to the user, but is helpful to confirm execution.
	return <>Silent Logout Successful</>;
};

export default SilentLogout;
