import CryptoJS from 'crypto-js';
import qs from 'query-string';
import axios from 'axios';
import { useEffect } from 'react';
import config from 'utils/config';

const generateVerifier = () => {
	let verifier = '';
	const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	for (let i = 0; i < 64; i++) {
		verifier += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return verifier;
};

const generateCodeChallenge = (verifier) => {
	return CryptoJS.SHA256(verifier)
		.toString(CryptoJS.enc.Base64)
		.replace(/=/g, '')
		.replace(/\+/g, '-')
		.replace(/\//g, '_');
};

export const removeAllLocalStorageItems = () => {
	[
		'currentLoggedInUser',
		'userToken',
		'idToken',
		'refreshToken',
		'tokenExpiry',
		'userTokenTimeout',
		'userTimezone',
		'locale',
		'companyId',
	].forEach((item) => {
		window.localStorage.removeItem(item);
	});
};

const setToken = (token) => {
	const anHour = 1000 * 60 * 60;
	const aDay = anHour * 24;

	window.localStorage.setItem('userToken', token.access_token);
	window.localStorage.setItem('idToken', token.id_token);
	window.localStorage.setItem('refreshToken', token.refresh_token);
	window.localStorage.setItem('refreshTokenTimeout', Date.now() + aDay * 15);
	window.localStorage.setItem(
		'userTokenTimeout',
		Date.now() + parseInt(token.expires_in, 10) * 1000
	);
	return token;
};

const startRefreshTokenTimer = () => {
	const tokenExpiryInMilliseconds = window.localStorage.getItem('userTokenTimeout');
	const millisecondsBeforeTokenExpires = 30000;

	return setTimeout(() => {
		// eslint-disable-next-line no-use-before-define
		refreshToken();
	}, Math.round(tokenExpiryInMilliseconds - Date.now() - millisecondsBeforeTokenExpires));
};

export const logout = () => {
	const idToken = window.localStorage.getItem('idToken');
	const { AUTHORISATION_SERVER_URL } = config();
	if (idToken) {
		const url = `${AUTHORISATION_SERVER_URL}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${window.location.origin}/login`;

		removeAllLocalStorageItems();

		window.location.assign(url);
	} else {
		removeAllLocalStorageItems();
		window.location.assign('/login');
	}
};

export const refreshToken = () => {
	const { AUTHORISATION_SERVER_URL } = config();
	const params = new URLSearchParams();
	params.append('refresh_token', window.localStorage.getItem('refreshToken'));
	params.append('grant_type', 'refresh_token');
	params.append('client_id', 'bright-account-webapp');
	const url = `${AUTHORISATION_SERVER_URL}/connect/token`;

	return axios
		.post(url, params)
		.then(({ data }) => data)
		.then(setToken)
		.catch(() => {
			logout();
		});
};

const login = () => {
	sessionStorage.setItem('redirectUrl', window.location.pathname);
	const { AUTHORISATION_SERVER_URL } = config();
	const verifier = generateVerifier();
	window.sessionStorage.setItem('verifier', verifier);

	const codeChallenge = generateCodeChallenge(verifier);
	const scope =
		'openid profile email offline_access multi-factor:write password:change sso:write';

	window.location.assign(
		`${AUTHORISATION_SERVER_URL}/connect/authorize?client_id=bright-account-webapp&redirect_uri=${window.location.origin}/login/callback&response_type=code&code_challenge=${codeChallenge}&code_challenge_method=S256&scope=${scope}`
	);
};

const isAuthenticated = () => {
	const userToken = window.localStorage.getItem('userToken');
	const expiryTime = window.localStorage.getItem('userTokenTimeout');

	if (userToken) {
		return expiryTime > Date.now();
	}

	return false;
};

const verifyToken = (code, verifier) => {
	const { AUTHORISATION_SERVER_URL } = config();
	const params = new URLSearchParams();
	params.append('client_id', 'bright-account-webapp');
	params.append('code', code);
	params.append('redirect_uri', `${window.location.origin}/login/callback`);
	params.append('code_verifier', verifier);
	params.append('grant_type', 'authorization_code');
	const url = `${AUTHORISATION_SERVER_URL}/connect/token`;

	return axios.post(url, params).then(({ data }) => data);
};

let isVerifying = null;

export default function useAuthentication() {
	useEffect(() => {
		const { pathname, search } = window.location;
		const verifier = window.sessionStorage.getItem('verifier');
		window.sessionStorage.removeItem('verifier');

		if (isVerifying) return;

		if (
			pathname.includes('/signup') ||
			pathname.includes('/package-client-error') ||
			pathname.includes('/reset-password') ||
			pathname.includes('/invite') ||
			pathname.includes('/owner-signup') ||
			pathname.includes('/silent-logout')
		) {
			return;
		}

		if (pathname === '/login/callback' && verifier) {
			const { code } = qs.parse(search);
			verifyToken(code, verifier)
				.then(setToken)
				.then(() => window.location.assign('/'))
				.catch((e) => {
					console.log(e); // eslint-disable-line no-console
				});
		} else if (pathname === '/logout') {
			logout();
		} else if (!isAuthenticated() && !verifier) {
			login();
		} else {
			isVerifying = Promise.resolve(startRefreshTokenTimer())
				.then(() => {
					isVerifying = null;
				})
				.catch(() => {
					isVerifying = null;
				});
		}
	}, []);

	return {
		isAuthenticated: isAuthenticated(),
	};
}
